export default {
	name: 'PropertyCreatePage',
	data() {
		// 校验物业名称
		const checkpedescc = (rule, value, callback) => {
			this.$http.post('/property/checkpedescc', {
				"hrid": this.$store.getters.loginid,
				"description": value
			}, {
				showLoading: false
			}).then((response) => {
				if (response.data.success) {
					return callback();
				} else {
					return callback(new Error(response.data.message));
				}
			}).catch(() => {
				return callback();
				// 错误
			});
		}
		// 校验物业简称格式
		const checkpeabFormat = (rule, value, callback) => {
			if (!/^[0-9A-Za-z]{4,20}$/.test(value)) {
				this.isPeabStatus = false
				return callback(new Error(this.$t('propertyCreate.peNameFormat')));
			} else {
				this.isPeabStatus = true
				return callback();
			}
		}
		// 校验物业简称
		const checkpeab = (rule, value, callback) => {
			this.$http.post('/property/checkpeab', {
				"hrid": this.$store.getters.loginid,
				"peab": value + this.$store.getters.suffix
			}, {
				showLoading: false
			}).then((response) => {
				if (response.data.success) {
					return callback();
				} else {
					return callback(new Error(response.data.message));
				}
			}).catch(() => {
				return callback();
				// 错误
			});
		}
		return {
			// 表单数据
			submitData: {
				description: '',
				peab: '',
				industry: '',
				address: '',
				alertphone: '',
				pelogo: ''
			},
			// 行业数据
			industrylist: [],
			// 图片展示
			imgFileList: [],
			// 校验规则
			submitRules: {
				description: [
					{ required: true, message: this.$t('propertyCreate.correctPeName'), trigger: 'blur', max: 200 },
					{ validator: checkpedescc, trigger: 'blur' }
				],
				peab: [
					{ required: true, message: this.$t('propertyCreate.peNameEmptyTips'), trigger: 'change' },
					{ validator: checkpeabFormat, trigger: 'change' },

					{ validator: checkpeab, trigger: 'blur' }
				],
				alertphone: [
					{
						pattern: /^[0-9]*$/, message: this.$t('propertyCreate.correctalTerphone'), trigger: 'blur'
					}
				]
			},
			// 物业简称合并是否显示
			isPeabStatus: false
		}
	},
	created() {

		// 判断是否拥有权限
		if (!this.$store.getters.selfregister) this.$emit('closeDrawer')
		this.$nextTick(()=>{
			this.getdatabind()
		})
		

	},
	computed: {
		peabUppercase (){
			// if(this.submitData.peab || this.submitData.peab =='') return ''
			return this.submitData.peab.toUpperCase()
		}
	},
	methods: {
		// 获取提示的图片
		getTipsImg(name) {
			let lang = this.$store.getters.lang
			const url = require(`@/assets/propertyDetail/${name}-${lang}.png`)
			return { url, list: [url] }
		},
		// 获取行业数据
		getdatabind() {

			this.$http.post('/common/databind', {
				hrid: this.$store.getters.loginid,
				penum: this.$store.getters.penum,
				"databindid": "INDUSTRY"
			}).then((response) => {
				if (response.data.success) {
					this.industrylist = response.data.content;
				} else {
					this.$alert(response.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
		},
		// 上传图片
		genBase64(imgId) {
			const file = imgId.raw;

			if (file) {
				let fileSize = file.size;
				if (fileSize < 1024 * 1024 * 2) {
					this.$refs.uploadBox.clearFiles()
					// this.imgFileList.splice(0,1)
					// this.forceUpdate()

					let r = new FileReader();
					r.onload = () => {
						this.submitData.pelogo = r.result
						this.imgFileList = [{ name: imgId.name, url: r.result }]
					};
					r.readAsDataURL(file);
				} else {
					this.$refs.uploadBox.clearFiles()
					this.$alert(this.$t('common.imageUploadTip', { size: '2MB' }), this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
					// showDialog(_this.pageText.PROMPT, _this.pageText.PICTURE2M, "error", "", [_this.pageText.OK]);
				}
			}
		},
		// 提交
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$http.post('/property/addpe', {
						"hrid": this.$store.getters.loginid,
						"description": this.submitData.description,

						"peabinput": this.peabUppercase,
						"peabsuffix": this.$store.getters.suffix,
						"industry": this.submitData.industry,
						"address": this.submitData.address,
						"pelogo": this.submitData.pelogo,

						"alertphone": this.submitData.alertphone
					}).then((response) => {
						if (response.data.success) {
							// this.headerChangePeDetail(item.penum, '');
							//   showDialog(_this3.pageText.PROMPT, _this3.pageText.PEABACCOUNTTIPS + _this3.peab + '-' + _this3.suffix, "success", `entitycreate.handelChangePeDetail('${response.data.content.penum}')`, [_this3.pageText.OK], true);


							this.$alert(this.$t('propertyCreate.peabAccountTips') + this.peabUppercase + '-' + this.$store.getters.suffix, this.$t('common.alertPrompt'), {
								callback: () => {
									this.$emit('createSuccess', response.data.content.penum)
									this.$emit('closeDrawer')
								},
								type: 'success',
								center: true

							})
							//   _this3.handleGuideFinish('entity'); // 该操作触发完成 <物业实体创建> 向导
						} else {
							this.$alert(response.data.message, this.$t('common.alertPrompt'), {
								type: 'error',
								center: true
							})
						}
					})
				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
		closeDrawer(){
			this.$emit('closeDrawer')
		}
	}
}