<template>
  <el-container class="container">
    <el-main class="main-wrapper">
      <el-form label-width="160px" :model="submitData" :rules="submitRules" ref="createForm">
        <el-form-item :label="$t('propertyCreate.entityName')" prop="description">
          <el-col :span="22">
            <el-input v-model="submitData.description"></el-input>
            <!-- <template slot="error" slot-scope="{ error }">
              {{error}}eee
            </template> -->
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('propertyCreate.abbreviation')" prop="peab">
          <el-col :span="22">
            <el-input class="peab-input" v-model="submitData.peab"
              ><template slot="append">{{ $store.getters.suffix }}</template></el-input
            >
            <div class="peab-tip-box" v-show="isPeabStatus">{{ $t('propertyCreate.officialPropertyNameTip') }} {{ peabUppercase }}-{{ $store.getters.suffix }}</div>
          </el-col>
          <el-col :span="1" :offset="1">
            <!-- <el-tooltip :content="$t('propertyCreate.loginMustInput')" placement="bottom" effect="light">
              <i class="el-icon-question el-icon"></i>
            </el-tooltip> -->
            <el-tooltip placement="bottom" effect="light">
              <template slot="content">
                <div style="margin-bottom: 20px">{{ $t('propertyCreate.loginMustInput') }}</div>
                <el-image :src="getTipsImg('abbr').url" :preview-src-list="getTipsImg('abbr').list">
                  <div slot="placeholder" class="image-placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </template>
              <i class="el-icon-question el-icon"></i>
            </el-tooltip>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('propertyCreate.industry')">
          <el-col :span="22">
            <el-select name="industry" placeholder="" v-model="submitData.industry" style="width: 100%">
              <el-option :key="industryKey" v-for="(industryOption, industryKey) in industrylist" :label="industryOption.description" :value="industryOption.value"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('propertyCreate.address')">
          <el-col :span="22">
            <el-input name="address" v-model="submitData.address"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('propertyCreate.emergencyContact')" prop="alertphone">
          <el-col :span="22">
            <el-input v-model="submitData.alertphone"></el-input>
          </el-col>
          <el-col :span="1" :offset="1">
            <!-- <el-tooltip :content="$t('propertyCreate.alertPhoneTips')" placement="bottom" effect="light">
              <i class="el-icon-question el-icon"></i>
            </el-tooltip> -->
            <el-tooltip placement="bottom" effect="light">
              <template slot="content">
                <div style="margin-bottom: 20px">{{ $t('propertyCreate.alertPhoneTips') }}</div>
                <el-image :src="getTipsImg('emergency-contact').url" :preview-src-list="getTipsImg('emergency-contact').list">
                  <div slot="placeholder" class="image-placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </template>
              <i class="el-icon-question el-icon"></i>
            </el-tooltip>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('propertyCreate.uploadPhoto')">
          <el-col :span="22">
            <!-- <el-upload ref="uploadBox" list-type="picture" action="" drag :auto-upload="false" accept="image/*" :show-file-list="true" :on-change="genBase64" :file-list="imgFileList">
              <i class="el-icon-upload"></i>
              <div class="el-upload__tip" slot="tip" style="line-height: 1.5">{{ $t('propertyCreate.imageUploadShowTip', { size: '2MB' }) }}</div>
            </el-upload> -->
            <el-upload ref="uploadBox" action="" drag :auto-upload="false" :show-file-list="false" :on-change="genBase64" accept="image/*">
              <img v-if="submitData.pelogo" :src="submitData.pelogo" class="avatar" />
              <i v-else class="el-icon-upload"></i>
              <div class="el-upload__tip" slot="tip" style="line-height: 1.5">{{ $t('propertyCreate.imageUploadShowTip', { size: '2MB' }) }}</div>
            </el-upload>
          </el-col>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="footer-wrapper" height="70px">
      <el-button @click="closeDrawer">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" @click="submitForm('createForm')">{{ $t('common.save') }}</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
