import PropertyCreatePage from '@/views/PropertyCreate/index.vue'
import CourseBtn from '@/components/CourseBtn.vue'
export default {
	name: 'PropertyListPage',
	components: {
		PropertyCreatePage,
		CourseBtn
	},
	data() {
		return {
			homeList: [],
			// 搜索条件
			propertyQuery: '',
			// 排序方式
			propertyOrderby: '',
			// 翻页配置
			pagesObj: {
				page: 1,
				pagesize: 12,
				total: 0
			},
			drawer: false,
			peNumLimit: false,
			createPropertySubscription:null
		}
	},
	created() {
		this.homelist()
	},
	mounted() {
		this.createPropertySubscription = this.$pubsub.subscribe('createPropertyEvent', () => {
			this.createProperty()
		});
	},
	beforeDestroy() {
		if (this.createPropertySubscription) {
			this.$pubsub.unsubscribe(this.createPropertySubscription);
		}

	},
	methods: {
		// 获取物业
		homelist(page) {
			// const loading = this.$loading({
			// 	target:this.$refs.propertyListBox,
			// 	lock: true,
			// });
			if (page) {
				this.pagesObj.page = page
			} else {
				this.pagesObj.page = 1
			}
			this.$http
				.post('/home/homelist', {
					hrid: this.$store.getters.loginid,
					penum: this.$store.getters.penum,
					query: this.propertyQuery,
					page: this.pagesObj.page,
					pagesize: this.pagesObj.pagesize,
					orderby: this.propertyOrderby,
				}, {
					loadTarget: this.$refs.propertyListBox
				})
				.then((response) => {
					if (response.data.success) {
						this.homeList = response.data.content;
						this.peNumLimit = response.data.isPeNumOverLimit;

						this.pagesObj = {
							page: response.data.page,
							pagesize: response.data.pagesize,
							total: response.data.total
						}
						// }
					} else {
						this.$alert(response.data.message, this.$t('common.alertPrompt'), {
							type: 'error',
							center: true
						})
						// showDialog(_this.pageText.PROMPT, response.data.message, 'error', '', [_this.pageText.OK]);
					}
					// loading.close()
				})
			// }
		},
		// 切换物业
		switchCurrentInfo(item) {
			if (this.$store.getters.penum != item.penum) {
				this.$refs.webLayout.headerChangePeDetail(item.penum, '');
			}
		},
		// 点击搜索
		searchEvent() {
			this.homelist()
		},
		// 创建物业
		createProperty() {
			if (this.peNumLimit) {
				this.$alert(this.$t('propertyList.numberLimit'), this.$t('common.alertPrompt'), {
					type: 'error',
					center: true
				})
				return;
			} else {
				// _this.handleNextStep()
				this.drawer = true
			}

		},
		// 关闭弹窗
		closeDrawer() {
			this.drawer = false
		},
		// 创建物业成功
		createPropertySuccess(penum) {
			this.homelist()
			this.$refs.webLayout.headerChangePeDetail(penum);
		}
	}
}