<template>
  <web-layout :title="$t('propertyList.proEntity')" :titleTips="$t('propertyList.proEntityTips')" ref="webLayout">
    <template v-slot:extend>
      <course-btn :course="['CREATE_PROPERTY_ENTITY']"></course-btn>
    </template>
    <template>
      <el-row :gutter="20" class="property-header">
        <el-col :span="11">
          <el-input :placeholder="$t('propertyList.searchOfficeName')" :title="propertyQuery ? '' : $t('propertyList.searchOfficeName')" v-model="propertyQuery" @keyup.enter.native="searchEvent" clearable>
            <el-button icon="el-icon-search" @click="searchEvent" slot="append"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4" class="sort-box">
          <el-select :placeholder="$t('common.sort')" v-model="propertyOrderby" @change="searchEvent">
            <el-option :label="$t('propertyList.nameAsc')" value="name_asc"></el-option>
            <el-option :label="$t('propertyList.nameDesc')" value="name_desc"></el-option>
            <el-option :label="$t('propertyList.createdTimeAsc')" value="crttime_asc"></el-option>
            <el-option :label="$t('propertyList.createdTimeDesc')" value="crttime_desc"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8" :offset="1" style="text-align: right" v-if="$store.getters.selfregister">
          <el-button id="create-pe-btn" @click="createProperty" type="primary" icon="el-icon-add-solid">{{ $t('common.create') }}</el-button>
        </el-col>
      </el-row>

      <div ref="propertyListBox">
        <el-empty v-if="homeList.length == 0" :description="$t('propertyList.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
        <div class="property-list-box" v-else>
          <div class="property-list-item" :class="{ active: $store.getters.penum == item.penum }" v-for="(item, index) of homeList" :key="index" @click="switchCurrentInfo(item)">
            <div class="pelogo-img-box">
              <el-image fit="cover" style="width: 100%; height: 100%" :src="item.pelogo || require('@/assets/defaultlogo.png')">
                <div slot="placeholder" class="image-placeholder">
                  <i class="el-icon-loading"></i>
                </div>
              </el-image>
              <!-- <img :src="item.pelogo || require('@/assets/defaultlogo.png')" /> -->
              <template v-if="$store.getters.penum == item.penum">
                <span class="current-status">{{ $t('propertyList.current') }}</span>
                <el-popover placement="bottom" width="170" trigger="hover">
                  <router-link to="/basicinfo" class="property-menu-item">{{ $t('propertyList.basinfo') }}</router-link>
                  <router-link to="/account" class="property-menu-item">{{ $t('propertyList.homeAccount') }}</router-link>
                  <router-link to="/location" class="property-menu-item">{{ $t('propertyList.homeLoc') }}</router-link>
                  <router-link to="/commonlist" class="property-menu-item">{{ $t('propertyList.comitemman') }}</router-link>
                  <router-link to="/task" class="property-menu-item">{{ $t('propertyList.instask') }}</router-link>
                  <router-link to="/rcwo" class="property-menu-item">{{ $t('propertyList.inslist') }}</router-link>
                  <router-link to="/event" class="property-menu-item">{{ $t('propertyList.increport') }}</router-link>
                  <router-link to="/notice" class="property-menu-item">{{ $t('propertyList.publishNotice') }}</router-link>
                  <span class="property-menu" slot="reference"><i class="el-icon-list-menu"></i></span>
                </el-popover>
              </template>
              <div class="hover-box">
                <div class="hover-main">
                  <div class="icon"><i class="el-icon-convertio"></i></div>
                  <div class="name">{{ $t('propertyList.switchProperty') }}</div>
                </div>
              </div>
            </div>
            <div class="content-box">
              <div class="title">{{ item.description }}</div>
              <!-- <div class="content">{{ item.description }}</div> -->
            </div>
          </div>
        </div>
      </div>

      <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="homelist"></pagination-box>
    </template>

    <el-drawer custom-class="create-property-drawer" :title="$t('propertyCreate.createPe')" :destroy-on-close="true" :visible.sync="drawer">
      <property-create-page @closeDrawer="closeDrawer" @createSuccess="createPropertySuccess"></property-create-page>
    </el-drawer>
  </web-layout>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
